<template>
    <div class="card" v-if="storeInformation.ecommerce != 'Ecart'">
        <div class="card-body" >
            <div class="row aling-items-center ">
                <div class="col">
                    <h6 class="m-0" v-text="$t('dashboard.product.syncProduct')"></h6>
                </div>
                <div class="col-auto">
                    <button class="btn btn-outline-success circle-25 " @click="fnApiSyncProduct()"
                        :disabled="loading.sync">
                        <i class="fa fa-sync fa-sm " v-if=" !loading.sync "></i>
                        <i class="fa fa-spinner fa-spin " v-else></i>
                    </button>
                </div>
            </div>
            <p class="text-muted">
                <small v-text="$t('dashboard.product.syncMessage')"> </small>
            </p>
            <p class="text-right">
                <small v-text="storeInformation.ecommerce"></small>
            </p>
        </div>

    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex'
    export default {
        computed: {
            ...mapState('EcartProduct', ['loading', 'storeInformation']),
        },
        methods: {
            ...mapActions('EcartProduct', ['fnApiSyncProduct']),
        }
    }
</script>